import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Footer from '../components/Footer';
import '../assets/scss/site.scss';
import Noty from 'noty';
import { resetPassword } from '../services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Noty.overrideDefaults({
  theme: 'limitless',
  layout: 'topRight',
  type: 'alert',
  timeout: 2500,
});

const PasswordReset = () => {
  const [status, setStatus] = useState();

  const onSubmit = e => {
    const formData = new FormData(e.target);
    const email = formData.get('email');
    if (email) {
      resetPassword(email)
        .then(() => {
          new Noty({
            text: 'Password reset mail was sent.',
            type: 'success',
          }).show();
          navigate('/login');
        })
        .catch(e => setStatus(e.message));
    } else {
      setStatus('Please fill in e-mail');
    }

    e.preventDefault();
  };

  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content d-flex justify-content-center align-items-center">
          <form className="login-form" onSubmit={onSubmit}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  <FontAwesomeIcon
                    icon={['fas', 'redo-alt']}
                    className="text-warning border-warning btn bg-transparent rounded-round border-3 btn-icon mb-3 mt-1 p-3"
                    style={{ width: '80px', height: '80px' }}
                  />

                  <h5 className="mb-0">Password recovery</h5>
                  <span className="d-block text-muted">
                    We&apos;ll send you instructions in email
                  </span>
                </div>

                <div className="form-group form-group-feedback form-group-feedback-left">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your email"
                  />
                  <div className="form-control-feedback">
                    <FontAwesomeIcon icon={['fas', 'envelope']} className="text-muted" />
                  </div>
                  {status ? (
                    <label id="status" className="validation-invalid-label" htmlFor="status">
                      {status}
                    </label>
                  ) : null}
                </div>
                <button type="submit" className="btn bg-blue btn-block">
                  <FontAwesomeIcon icon={['fas', 'redo-alt']} className="mr-2" />
                  Reset password
                </button>
              </div>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default PasswordReset;
